<template>
  <h3 class="checkout__title">Receiver Information</h3>

  <div class="checkout__form">
    <div>
      <select class="btn btn-theme-select dropdown-toggle col-12" v-if="receiverAddresses.length > 0">
        <option value="">Select an existing address</option>
        <option v-for="(address, index) in receiverAddresses" :key="index" :value="address">
          {{ address.first_name + " " + address.last_name }}
        </option>
      </select>

      <form @submit.prevent="onSaveAddress">
        <div class="form-row">
          <div class="col-12 col-lg-6 form-group--block">
            <label>First name: <span>*</span></label>
            <input
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.first_name') }"
              type="text"
              placeholder="Enter the firstname"
              v-model="formData.shipping.first_name"
            />
            <div class="invalid-feedback" v-if="$errors.has('shipping.first_name')">
              {{ $errors.first("shipping.first_name") }}
            </div>
          </div>

          <div class="col-12 col-lg-6 form-group--block">
            <label>Last name<span>*</span></label>
            <input
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.last_name') }"
              type="text"
              placeholder="Enter the lastname"
              v-model="formData.shipping.last_name"
            />
            <div class="invalid-feedback" v-if="$errors.has('shipping.last_name')">
              {{ $errors.first("shipping.last_name") }}
            </div>
          </div>

          <div class="col-12 col-lg-6 form-group--block">
            <label>Country: <span>*</span></label>
            <select class="form-control drobdown" name="state" v-model="formData.shipping.country"
              :class="{ 'is-invalid': $errors.has('shipping.country') }">
              <option value="">Select a country</option>
              <option :value="country.code" v-for="(country, index) in countriesAvailable" :key="index">
                {{ country.name }}
              </option>
            </select>

            <div class="invalid-feedback" v-if="$errors.has('shipping.country')">
              {{ $errors.first("shipping.country") }}
            </div>
          </div>

          <div class="col-12 col-lg-6 form-group--block">
            <label>State: <span>*</span></label>
            <select
              class="form-control drobdown"
              :class="{ 'is-invalid': $errors.has('shipping.state') }"
              name="state"
              v-model="state"
              v-if="states.length"
            >
              <option value="">Select a state</option>
              <option :value="state" v-for="(state, index) in states" :key="index">
                {{ state.default_name }}
              </option>
            </select>

            <input
              type="text"
              name="state"
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.state') }"
              v-model="formData.shipping.state"
              v-else
              placeholder="Enter the state"
            />
            <div class="invalid-feedback" v-if="$errors.has('shipping.state')">
              {{ $errors.first("shipping.state") }}
            </div>
          </div>

          <div class="col-12 form-group--block">
            <label>Town/ City: <span>*</span></label>
            <select
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.city') }"
              name="city"
              v-model="formData.shipping.city"
              v-if="cities.length"
            >
              <option value="">Select a city</option>
              <option :value="city.default_name" v-for="(city, index) in cities" :key="index">
                {{ city.default_name }}
              </option>
            </select>

            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.city') }"
              v-model="formData.shipping.city"
              v-else
              placeholder="Enter the town/city"
            />
            <div class="invalid-feedback" v-if="$errors.has('shipping.city')">
              {{ $errors.first("shipping.city") }}
            </div>
          </div>

          <div class="col-12 form-group--block">
            <label>Street address: <span>*</span></label>
            <input
              type="text"
              placeholder="Enter the street address"
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.address1') }"
              v-model="formData.shipping.address1[0]"
            />
            <div class="invalid-feedback" v-if="$errors.has('shipping.address1')">
              {{ $errors.first("shipping.address1") }}
            </div>
          </div>

          <div class="col-12 form-group--block">
            <label>Postcode (Optional):</label>
            <input
              type="text"
              v-model="formData.shipping.postcode"
              placeholder="Enter the postcode"
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.postcode') }"
            />
            <div class="invalid-feedback" v-if="$errors.has('shipping.postcode')">
              {{ $errors.first("shipping.postcode") }}
            </div>
          </div>

          <div class="col-12 form-group--block">
            <label>Phone: <span>*</span></label>
            <div class="input-group"
                :class="{ 'is-invalid': $errors.has('shipping.phonecode') || $errors.has('shipping.phone') }"
            >
              <div class="input-group-addon">
                <select class="form-control" style="width:100px"
                  :class="{ 'is-invalid': $errors.has('shipping.phonecode') }"
                  v-model="formData.shipping.phonecode" >
                  <option value="" disabled>Select</option>
                  <option v-for="(country, index) in countriesWithPhonecode" :key="index" :value="country.phonecode">
                    ({{ country.phonecode }}) {{ country.name }}
                  </option>
                </select>
              </div>

              <input
                class="form-control"
                :class="{ 'is-invalid': $errors.has('shipping.phone') }"
                type="text"
                placeholder="Enter the phone number"
                v-model="formData.shipping.phone"
              />
            </div>
          
            <div class="invalid-feedback" v-if="$errors.has('shipping.phone')">
              {{ $errors.first("shipping.phone") }}
            </div>
          </div>

          <div class="col-12 form-group--block">
            <label>Email address: <span>*</span></label>
            <input
              type="email"
              v-model="formData.shipping.email"
              placeholder="Enter the email address"
              class="form-control"
              :class="{ 'is-invalid': $errors.has('shipping.email') }"
            />
            <div class="invalid-feedback" v-if="$errors.has('shipping.email')">
              {{ $errors.first("shipping.email") }}
            </div>
          </div>

          <div class="col-12 form-group--block mg-t-10">
            <div class="float-left">
              <button type="button" class="btn btn-theme" @click="$router.back()">
                <i class="fa fa-long-arrow-left"></i>
                 Shopping Cart
              </button>
            </div>
            <div class="text-right">
              <button type="submit" class="btn btn-theme">
                {{ isLoading ? "Please wait.." : "Delivery Date & Note" }}
                <i class="fa fa-long-arrow-right"></i>
              </button>
            </div>
            
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      option_show: false,
      isFormValid: false,
      shipping: {
        shipping_method: "",
      },
      state: "",
      formData: {
        billing: {},
        shipping: {
          address1: [],
          first_name: "",
          last_name: "",
          email: "",
          country: "LK",
          state: "",
          phonecode: "+94",
          phone: "",
          category: "receiver",
        },
      },
    };
  },

  props: {
    senderAddresse: {
      type: Object,
    },
    onChangePage: {
      type: Function,
    },
  },

  watch: {
    cart: {
      handler() {
        this.formData.billing.use_for_shipping = false;
        if (this.cart.billing_address) {
          this.formData.billing = this.cart.billing_address;
        }

        if (this.cart.shipping_method) {
          this.shipping.shipping_method = this.cart.shipping_method;
        }
      },
    },

    senderAddresse: {
      handler(newVal) {
        this.formData.billing = Object.assign(this.formData.billing, newVal);
      },
      immediate: true,
      deep: true,
    },

    state: {
      handler(newVal) {
        this.formData.shipping.state = newVal.default_name;
      },
      immediate: true,
      deep: true,
    },
  },

  beforeMount() {},

  mounted() {
    this.fetchReceiverAddresses();
    this.fetchCountriesAvailable();
    this.fetchStatesAvailable();
    this.fetchCitiesAvailable();
  },

  computed: {
    ...mapGetters("cart", ["cart", "merged_rates", "isLoading"]),
    ...mapGetters("country", ["countriesAvailable", "statesByCountryAvailable", "citiesByCountryStateAvailable", "countriesWithPhonecode"]),
    ...mapGetters("address", ["receiverAddresses"]),

    states() {
      return this.statesByCountryAvailable(this.formData.shipping.country);
    },

    cities() {
      return this.citiesByCountryStateAvailable(this.formData.shipping.country, this.state.code);
    },
  },

  methods: {
    ...mapActions("cart", ["saveAddress", "saveShipping"]),
    ...mapActions("address", ["fetchReceiverAddresses"]),
    ...mapActions("country", ["fetchCountriesAvailable", "fetchStatesAvailable", "fetchCitiesAvailable"]),

    onSaveAddress() {
      this.formData.billing.use_for_shipping = false;
      this.saveAddress(this.formData).then(() => {
        this.$router.push({name: 'checkout-delivery'})
      });
    },
  },
};
</script>

<style scoped>
.btn-theme-select {
  border: 1px solid #ff7204;
  color: #ff7204;
  font-size: 14px;
  font-weight: 560;
}
.theme-select option {
  font-size: 14px;
}

.mg-t-10 {
  margin-top: 10px;
}
</style>
