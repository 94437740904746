<template>
  <h3 class="checkout__title">Sender Information</h3>
  <div class="card card-margin col-12 mt-4" v-if="editAddressInline != true && senderAddresse">
    <div class="card-body">
      <div class="name">{{ senderAddresse.first_name }} {{ senderAddresse.last_name }}</div>
      <div class="address" v-if="senderAddresse.address1">{{ senderAddresse.address1[0] }}</div>
      <div class="city" v-if="senderAddresse.city || senderAddresse.postcode">
        {{ senderAddresse.city }} 
        {{ senderAddresse.city && senderAddresse.postcode ?  '-' : '' }} 
        {{ senderAddresse.postcode }}
      </div>
      <div class="county" v-if="senderAddresse.country_name">{{ senderAddresse.country_name }}</div>
      <div class="email" v-if="senderAddresse.email">{{ senderAddresse.email }}</div>
      <div class="phone" v-if="senderAddresse.phone">{{ senderAddresse.phonecode }} {{ senderAddresse.phone }}</div>

      <div class="row pagination justify-content-end mr-1 mb-2">
        <button type="button" class="btn btn-theme" @click="editAddressInline = true">
          <i class="icon-pen"></i> Edit
        </button>
      </div>
    </div>
  </div>

  <!-- from -->
  <form @submit.prevent="onSubmit()" v-if="editAddressInline">
    <div class="row">
      <div class="col-12 col-lg-6 form-group--block">
        <label>First name: <span>*</span></label>
        <input
          class="form-control"
          :class="{ 'is-invalid': $errors.has('first_name') }"
          type="text"
          placeholder="Enter the first name"
          v-model="formDate.first_name"
        />
        <div class="invalid-feedback" v-if="$errors.has('first_name')">
          {{ $errors.first("first_name") }}
        </div>
      </div>

      <div class="col-12 col-lg-6 form-group--block">
        <label>Last name: <span>*</span></label>
        <input
          class="form-control"
          :class="{ 'is-invalid': $errors.has('last_name') }"
          type="text"
          placeholder="Enter the last name"
          v-model="formDate.last_name"
        />
        <div class="invalid-feedback" v-if="$errors.has('last_name')">
          {{ $errors.first("last_name") }}
        </div>
      </div>

      <div class="col-12 col-lg-6 form-group--block">
        <label>Country: <span>*</span></label>
        <select
          class="form-control drobdown"
          :class="{ 'is-invalid': $errors.has('country') }"
          name="country"
          v-model="formDate.country"
        >
          <option value="">Select a country</option>
          <option :value="country.code" v-for="(country, index) in countries" :key="index">
            {{ country.name }}
          </option>
        </select>
        <div class="invalid-feedback" v-if="$errors.has('country')">
          {{ $errors.first("country") }}
        </div>
      </div>

      <div class="col-12 col-lg-6 form-group--block">
        <label>State: <span>*</span></label>
        <select
          class="form-control drobdown"
          :class="{ 'is-invalid': $errors.has('state') }"
          name="state"
          v-model="formDate.state"
          v-if="states.length"
        >
          <option value="">Select a state</option>
          <option :value="state.default_name" v-for="(state, index) in states" :key="index">
            {{ state.default_name }}
          </option>
        </select>
        <input
          type="text"
          name="state"
          class="form-control"
          :class="{ 'is-invalid': $errors.has('state') }"
          placeholder="Enter the state"
          v-model="formDate.state"
          v-else
        />
        <div class="invalid-feedback" v-if="$errors.has('state')">
          {{ $errors.first("state") }}
        </div>
      </div>

      <div class="col-12 col-lg-6 form-group--block">
        <label>City: <span>*</span></label>
        <input
          class="form-control"
          :class="{ 'is-invalid': $errors.has('city') }"
          type="text"
          placeholder="Enter the city"
          v-model="formDate.city"
        />
        <div class="invalid-feedback" v-if="$errors.has('city')">
          {{ $errors.first("city") }}
        </div>
      </div>

      <div class="col-12 col-lg-6 form-group--block">
        <label>Postcode (Optional):</label>
        <input
          class="form-control"
          :class="{ 'is-invalid': $errors.has('postcode') }"
          type="text"
          placeholder="Enter the postcode"
          v-model="formDate.postcode"
        />
        <div class="invalid-feedback" v-if="$errors.has('postcode')">
          {{ $errors.first("postcode") }}
        </div>
      </div>

      <div class="col-12 col-lg-6 form-group--block">
        <label>Phone: <span>*</span></label>
        <div class="input-group"
            :class="{ 'is-invalid': $errors.has('phonecode') || $errors.has('phone') }"
        >
        <div class="input-group-addon">
            <select class="form-control" style="width:100px"
              :class="{ 'is-invalid': $errors.has('phonecode') }"
              v-model="formDate.phonecode" >
              <option value="">Select</option>
              <option v-for="(country, index) in countriesWithPhonecode" :key="index" :value="country.phonecode">
                ({{ country.phonecode }}) {{ country.name }}
              </option>
            </select>
          </div>
          <input
            class="form-control"
            :class="{ 'is-invalid': $errors.has('phone') }"
            type="text"
            placeholder="Enter the phone number"
            v-model="formDate.phone"
          />
        </div>
        
        <div
          class="invalid-feedback"
          v-show="$errors.has('phonecode')"
        >
          {{ $errors.first("phonecode") }}
        </div>
        <div
          class="invalid-feedback"
          v-show="$errors.has('phone')"
        >
          {{ $errors.first("phone") }}
        </div>
      </div>

      <div class="col-12 col-lg-6 form-group--block">
        <label>Email: <span>*</span></label>
        <input
          class="form-control"
          :class="{ 'is-invalid': $errors.has('email') }"
          type="email"
          required
          placeholder="Enter the email"
          v-model="formDate.email"
        />
        <div class="invalid-feedback" v-if="$errors.has('email')">
          {{ $errors.first("email") }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-12 form-group--block">
        <label>Address: <span>*</span></label>
        <textarea
          class="form-control"
          :class="{ 'is-invalid': $errors.has('address1') }"
          rows="4"
          cols="50"
          placeholder="Enter the address"
          v-model="formDate.address1[0]"
        >
        </textarea>
        <div class="invalid-feedback" v-if="$errors.has('address1')">
          {{ $errors.first("address1") }}
        </div>
      </div>
    </div>

       <div class="form-group--block mg-t-10">
            <div class="text-right">
              <button type="submit" class="btn btn-theme" :disabled="isSubmitted">
                {{ isLoading ? "Please wait.." : "Save" }}
                <i class="fa fa-update"></i>
              </button>
            </div>
      </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      editAddressInline: false,
      isLoading: false,
      isSubmitted: false,
      formDate: {
        address1: [],
        first_name: "",
        last_name: "",
        country: "",
        state: "",
        city: "",
        postcode: "",
        phonecode: "",
        phone: "",
        category: "sender",
      },
    };
  },

  props: {
    senderAddresse: {
      type: Object,
    },
    onChangePage: {
      type: Function,
    },
  },

  computed: {
    ...mapGetters("country", ["countries", "statesByCountry", "countriesWithPhonecode"]),

    states() {
      return this.statesByCountry(this.formDate.country);
    },
  },

  watch: {
    senderAddresse: {
      handler(newVal) {
        this.formDate = Object.assign(this.formDate, newVal);
        if (this.formDate.id) {
          this.editMode = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions("address", ["addAddress", "editAddress"]),

    onSubmit() {
      this.isSubmitted = true;
      if (this.editMode) {
        this.editAddress(this.formDate)
          .then((response) => {
            this.isSubmitted = false;
            var address = Object.assign(this.formDate, response.data);
            this.$emit("update:senderAddresse", address);
            this.editAddressInline = false
          })
          .catch(() => {
            this.isSubmitted = false;
          });
      } else {
        this.addAddress(this.formDate)
          .then((response) => {
            this.isSubmitted = false;
            var address = Object.assign(this.formDate, response.data);
            this.$emit("update:senderAddresse", address);
            this.onChangePage("receiver");
          })
          .catch(() => {
            this.isSubmitted = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.card-margin {
  border: 1px solid rgb(221, 221, 221);
}
</style>