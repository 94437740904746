<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li class="active"><a href="#">Checkout</a></li>
          <li><a href="javascript:void(0);">Address</a></li>
        </ul>
      </div>
    </div>
    <section class="section--checkout">
      <div class="container">
        <!-- New Product component -->
        <!-- <NewProducts /> -->

        <h2 class="page__title m-0 p-0">Address</h2>
        <div class="checkout__content">
          <div class="checkout__header">
            <CheckoutTopSection />
          </div>
          <div class="row" v-if="!isLoading">
            <div class="col-sm-12 col-lg-8 offset-lg-2">
              <SenderAddress
                :senderAddresse="senderAddresse"
                @update:senderAddresse="senderAddresse = $event"
                :onChangePage="onChangePage"
              />
            </div>

            <div class="col-12 col-lg-8 offset-lg-2">
              <RecipientDetails
                v-if="page == 'receiver'"
                :senderAddresse="senderAddresse"
                :onChangePage="onChangePage"
              />
            </div>
          </div>
          <div class="row my-5" v-else>
            <div class="col-12 col-lg-7">
              <Skeletor height="500" />
            </div>
            <div class="col-12 col-lg-5">
              <Skeletor height="500" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
import CheckoutTopSection from "./Components/CheckoutTopSection";
// import NewProducts from "./Components/NewProducts";
import SenderAddress from "./Components/SenderAddress";
import RecipientDetails from "./Components/RecipientDetails";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Checkout",
  components: {
    Skeletor,
    // NewProducts,
    CheckoutTopSection,
    SenderAddress,
    RecipientDetails,
  },
  data() {
    return {
      page: "sender",
      isLoading: true,
      defaultSenderAddresse: null
    };
  },

  mounted() {
    this.isLoading = true;
    this.fetchSenderAddresses().then(() => {
      this.isLoading = false;
      if (this.senderAddresses.length > 0) {
        this.page = "receiver";
      }
    });
    this.fetchCountries({ sort: "name", order: "asc" });
    this.fetchStates();
    this.fetchCities();
    this.fetchCountriesWithPhonecode();
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("address", ["senderAddresses"]),

    senderAddresse: {
      get() {        
        if (this.defaultSenderAddresse) {
          return this.defaultSenderAddresse;
        }else if (this.senderAddresses.length > 0) {
          return this.senderAddresses[0];
        }else if (this.user) {
          var sender = Object.assign({}, this.user);
          delete sender['id'];
          return sender;
        }

        return {};
      },
      set(address) {
        this.defaultSenderAddresse = address;
      }
    }
  },

  watch: { },

  methods: {
    ...mapActions("address", ["fetchSenderAddresses"]),
    ...mapActions("country", ["fetchCountries", "fetchStates", "fetchCities", "fetchCountriesWithPhonecode"]),

    onChangePage(page) {
      this.page = page;
    },
  },
};
</script>

<style>
a.checkout__order.disabled {
  background: #ffd6b6;
  cursor: not-allowed;
}
.icon-cross {
  padding: 1px;
  background-color: #d92022;
  color: #ffff;
  border-radius: 50px;
  font-size: 10px;
}
</style>